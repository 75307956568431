import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Flex from "../components/Flex";
import { markdown } from "../theme";

export default function FourOhFour() {
  return (
    <Layout>
      <Flex direction="column" grow={1} halign="stretch">
        <h1>Page or resource not found</h1>
        <p style={markdown.p}>
          Oops! The page or resource you are looking for does not exist.
        </p>
        <Link to="/">
          <p style={markdown.p}>Go Back</p>
        </Link>
      </Flex>
    </Layout>
  );
}
